
import { NodeInfo } from '@/types/state'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class DefaultHome extends Vue {
    @Prop()
    node!: NodeInfo

    @Prop()
    outOfUse!: boolean

    @Prop()
    updated!: boolean

    @Prop()
    isModOrAdmin!: boolean

    toggleComparison(): void {
        this.$store.commit('default/TOGGLE_COMPARISON', { ...this.node })
    }

    get compare(): boolean {
        return this.$store.getters['default/ComparedNodes']?.filter((n: NodeInfo) => n.id == this.node.id)[0] != null
    }

    get title(): string {
        return this.node.alias
    }
    get emraSerialId(): string {
        return this.node.emraSerialId
    }
    
    get statusColor(): string {
        return this.node.nodeStatus?.hexColor?.padStart(7, "#") ?? "grey"
    }

    get statusText(): string {
        return this.node.nodeStatus?.label ?? "Onbekend"
    }

    get statusDescription(): string {
        return this.node.nodeStatus?.description ?? ""
    }

    formatDate(dateString: string): string {
        const date = new Date(dateString)
        return date.getDate().toString().padStart(2, '0') + "/" +
               (date.getMonth() + 1).toString().padStart(2, '0') + "/" +
               date.getFullYear()
    }
    
    get formattedDate(): string {
        return this.node?.updatedAt ? this.formatDate(this.node?.updatedAt) : "-"
    }

    get formattedPlacementDate(): string {
        return this.node?.placementDate ? this.formatDate(this.node?.placementDate) : ""
    }

    get placementDateWithinMonth(): boolean {
        const date = this.node?.placementDate
        if (!date) return false

        const d1 = new Date(date).getTime()
        const now = Date.now()

        return  (d1 - now)/(1000*60*60*24) >= 0 && (d1 - now)/(1000*60*60*24) <= 31
    }

    get tooltipMessage(): string {
        const date = new Date(this.node?.updatedAt)
        const stringDate = this.formattedDate
        const stringTime = date?.getHours().toString().padStart(2, '0') + ":" + 
            date?.getMinutes().toString().padStart(2, '0')

        return (this.node?.updatedAt) ? 
            `Laatste data opgehaald op ${ stringDate } om ${ stringTime } uur` :
            `Er is nog geen data opgehaald.`
    }
}
