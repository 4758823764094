
import { NodeInfo } from '@/types/state'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserRole } from '@/enums'

@Component({
    components: {
    }
})
export default class DefaultHome extends Vue {
    @Prop()
    nodes!: NodeInfo[]

    @Prop()
    updatedNodes!: Array<any>

    @Prop()
    searchString!: string

    get headers() {
        return [
            {
                sortable: false,
                filterable: false,
                value: "modifiedCol",
                width: "1px"
            },
            {
                text: this.isModOrAdmin ? "Ophaal- of plaatsingsdatum" : "Laatste data",
                sortable: false,
                filterable: false,
                value: "retrievalOrPlacementDateCol",
                width: "1px"
            },
            {
                text: this.isModOrAdmin ? "Status" : "",
                sortable: false,
                filterable: false,
                value: "retrievalStatusCol",
                width: "1px"
            },
            {
                text: "EMRA-box",
                sortable: false,
                value: "nameCol"
            },
            {
                sortable: false,
                filterable: false,
                align: "right",
                value: "bookmarkCol"
            }
        ]
    }

    get items(): any[] {
        return this.nodes.map(n =>
            ({
                modified: this.updatedNodes.includes(n.id),
                serialNumber: n.emraSerialId ? "EMRA-" + n.emraSerialId : "",
                name: n.alias,
                retrievalOrPlacementDate: this.getFormattedRetrievalOrPlacementDate(n),
                retrievalOrPlacementIcon: this.getRetrievalOrPlacementIcon(n),
                retrievalOrPlacementTooltip: this.getRetrievalOrPlacementTooltip(n),
                retrievalStatus: n.nodeStatus?.label ?? "Onbekend",
                retrievalStatusColor: n.nodeStatus?.hexColor?.padStart(7, "#") ?? "grey",
                retrievalStatusDescription: n.nodeStatus?.description ?? "-",
                node: n
            })
        )
    }

    customFilter(_: any, search: string, item: any): boolean {
        const searchLower = search.toLocaleLowerCase()
        return item?.name?.toLocaleLowerCase().includes(searchLower)
            || (this.isModOrAdmin && item?.serialNumber?.toLocaleLowerCase().includes(searchLower))
    }

    getFormattedRetrievalOrPlacementDate(node: NodeInfo): string {
        if (node.updatedAt) return this.formatDate(node.updatedAt)
        if (node.placementDate) {
            const date = new Date(node.placementDate)
            if (date && date.valueOf() >= Date.now()) return this.formatDate(node.placementDate)
        }
        return ""
    }

    getRetrievalOrPlacementIcon(node: NodeInfo): string {
        if (node.updatedAt) return "mdi-calendar-month-outline"
        if (node.placementDate) {
            const date = new Date(node.placementDate)
            if (date && date.valueOf() >= Date.now()) return "mdi-calendar-export"
        }
        return ""
    }

    getRetrievalOrPlacementTooltip(node: NodeInfo): string {
        const icon = this.getRetrievalOrPlacementIcon(node)
        const date = this.getFormattedRetrievalOrPlacementDate(node)

        switch (icon) {
            case "mdi-calendar-month-outline": return `Data laatst opgehaald op ${date}`
            case "mdi-calendar-export":        return `EMRA-box wordt geplaatst op ${date}`
            default:                           return ""
        }
    }

    get comparedNodes(): NodeInfo[] {
        return this.$store.getters['default/ComparedNodes']
    }

    get userRole(): string {
        return this.$store.getters['identity/role']
    }

    get isModOrAdmin(): boolean {
        return this.userRole == 'Moderator' || this.userRole == 'Admin'
    }

    isInComparison(node: NodeInfo): boolean {
        return this.comparedNodes?.filter((n: NodeInfo) => n.id == node.id)[0] != null
    }

    toggleComparison(node: NodeInfo): void {
        this.$store.commit('default/TOGGLE_COMPARISON', { ...node })
    }

    formatDate(dateString: string): string {
        const date = new Date(dateString)
        return date.getDate().toString().padStart(2, '0') + "/" +
               (date.getMonth() + 1).toString().padStart(2, '0') + "/" +
               date.getFullYear()
    }
    
    setDetailNode(nodeItem: any): void {
        const node = nodeItem.node as NodeInfo
        if (node) {
            this.$store.commit('detailNode/SET_NODE_INFO', { ...node })
            this.$store.commit('meters/SET_NODE_INFO', { ...node })
            this.$store.commit('modNode/SET_NODE_INFO', { ...node })
            this.$store.commit('emraPageInfo/SET_NODE_INFO', { ...node } )
            this.$router.push({ name: this.userRole == UserRole.User ? "DataPage" : (this.userRole != UserRole.None ? "ModEMRAPage" : "UserHome") })
            window.scrollTo(0, 0)
        }
    }
    
}
